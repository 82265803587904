.p-0 {padding: 0!important;}
.p-5 {padding: 5px!important;}
.p-10 {padding: 10px!important;}
.p-15 {padding: 15px!important;}
.p-20 {padding: 20px!important;}
.p-25 {padding: 25px!important;}
.p-30 {padding: 30px!important;}

.p-t-0 {padding-top: 0!important;}
.p-t-5 {padding-top: 5px!important;}
.p-t-10 {padding-top: 10px!important;}
.p-t-15 {padding-top: 15px!important;}
.p-t-20 {padding-top: 20px!important;}
.p-t-25 {padding-top: 25px!important;}
.p-t-30 {padding-top: 30px!important;}

.p-l-0 {padding-left: 0!important;}
.p-l-5 {padding-left: 5px!important;}
.p-l-10 {padding-left: 10px!important;}
.p-l-15 {padding-left: 15px!important;}
.p-l-20 {padding-left: 20px!important;}
.p-l-25 {padding-left: 25px!important;}
.p-l-30 {padding-left: 30px!important;}

.p-r-0 {padding-right: 0!important;}
.p-r-5 {padding-right: 5px!important;}
.p-r-10 {padding-right: 10px!important;}
.p-r-15 {padding-right: 15px!important;}
.p-r-20 {padding-right: 20px!important;}
.p-r-25 {padding-right: 25px!important;}
.p-r-30 {padding-right: 30px!important;}

.p-b-0 {padding-bottom: 0!important;}
.p-b-5 {padding-bottom: 5px!important;}
.p-b-10 {padding-bottom: 10px!important;}
.p-b-15 {padding-bottom: 15px!important;}
.p-b-20 {padding-bottom: 20px!important;}
.p-b-25 {padding-bottom: 25px!important;}
.p-b-30 {padding-bottom: 30px!important;}

.bg-primary{background-color: $brand-primary}
.bg-info{background-color: $brand-info}
.bg-success{background-color: $brand-success}
.bg-warning{background-color: $brand-warning}
.bg-danger{background-color: $brand-danger}
.bg-white { background-color: #fff}

.m-0 {padding: 0!important;}
.m-5 {padding: 5px!important;}
.m-10 {padding: 10px!important;}
.m-15 {padding: 15px!important;}
.m-20 {padding: 20px!important;}
.m-25 {padding: 25px!important;}
.m-30 {padding: 30px!important;}

.m-t-0 {padding-top: 0!important;}
.m-t-5 {padding-top: 5px!important;}
.m-t-10 {padding-top: 10px!important;}
.m-t-15 {padding-top: 15px!important;}
.m-t-20 {padding-top: 20px!important;}
.m-t-25 {padding-top: 25px!important;}
.m-t-30 {padding-top: 30px!important;}

.m-l-0 {padding-left: 0!important;}
.m-l-5 {padding-left: 5px!important;}
.m-l-10 {padding-left: 10px!important;}
.m-l-15 {padding-left: 15px!important;}
.m-l-20 {padding-left: 20px!important;}
.m-l-25 {padding-left: 25px!important;}
.m-l-30 {padding-left: 30px!important;}

.m-r-0 {padding-right: 0!important;}
.m-r-5 {padding-right: 5px!important;}
.m-r-10 {padding-right: 10px!important;}
.m-r-15 {padding-right: 15px!important;}
.m-r-20 {padding-right: 20px!important;}
.m-r-25 {padding-right: 25px!important;}
.m-r-30 {padding-right: 30px!important;}

.m-b-0 {padding-bottom: 0!important;}
.m-b-5 {padding-bottom: 5px!important;}
.m-b-10 {padding-bottom: 10px!important;}
.m-b-15 {padding-bottom: 15px!important;}
.m-b-20 {padding-bottom: 20px!important;}
.m-b-25 {padding-bottom: 25px!important;}
.m-b-30 {padding-bottom: 30px!important;}


